






















import { defineComponent } from '@vue/composition-api';
import { useAuth } from '@/admin/auth';

export default defineComponent({
  name: 'Account',
  setup() {
    const auth = useAuth();
    const items = [
      {
        title: 'メールアドレス変更',
        to: '/account/email',
      },
      {
        title: 'パスワード変更',
        to: '/account/password',
      },
      {
        title: '電話番号変更',
        to: '/account/phone',
      },
    ];

    const logout = async () => {
      await auth.signout();
    }

    return { items, logout };
  }
});
